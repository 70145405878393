import { mean } from 'd3-array';
import { Dispatch, SetStateAction } from 'react';
import { SWRConfiguration } from 'swr';

import { GetTimeSeriesResponse } from '@/utils/defined.types';

// Charts Component Utils
export const MAX_CHART_HEIGHT = 257;
export const MAX_CHART_WIDTH = 770;

/**
 * Chart Area Props for Line Chart with Tool Tip.
 * @param width - is responsive and dynamic
 * @param height - is constant
 */
export type ChartAreaProps = {
  width: number;
  height: number;
  tokenData: TokenPrice[];
  resolution: string;
  margin?: { top: number; right: number; bottom: number; left: number };
  tokenViewPairActive: boolean;
  variant: 'token-page' | 'home-page';
  // if variant === 'home-page' we set state for the <ChartHeaderCard/> in MostPopularToken section for the homepage.
  setTooltipData?: Dispatch<SetStateAction<TokenPrice | undefined>>;
};

/**
 * Passed into the useChartData hook and passed to Defined.api
 */
export interface ChartParams {
  address?: string;
  chainId?: number;
  chartRangeInMinutes: number;
  resolution: string;
  currencyCode: string;
  removeLeadingNullValues: boolean;
  swrOptions?: SWRConfiguration;
}

export interface TokenPrice {
  closing: number;
  date: string;
}

export const CHART_RANGE_MINUTES = {
  MINUTE: 1,
  FIVE_MINUTES: 5,
  FIFTEEN_MINUTES: 15,
  THIRTY_MINUTES: 30,
  HOUR: 60,
  FOUR_HOURS: 240,
  TWELVE_HOURS: 720,
  DAILY: 1440,
  FIVE_DAYS: 7200,
  WEEKLY: 10080,
  FIFTEEN_DAYS: 21600,
  MONTHLY: 43800,
  TWO_MONTHS: 87600,
  EIGHT_MONTHS: 350400,
  YEARLY: 525960,
  TWO_YEARS: 1051920,
  FOUR_YEARS: 2103840,
  SIXTEEN_YEARS: 8415360,
  MAX: 0,
} as const;

/**
 * Time frame for each candle.
 * Defined.di API - Available options are 1, 5, 15, 30, 60, 240, 720, 1D, and 7D.
 */
export const TIME_FRAMES = {
  ONE_MINUTE: '1',
  FIVE_MINUTES: '5',
  FIFTEEN_MINUTES: '15',
  THIRTY_MINUTES: '30',
  ONE_HOUR: '60',
  FOUR_HOURS: '240',
  TWELVE_HOURS: '720',
  DAY: '1D',
  WEEKLY: '7D',
};

export const RESOLUTION_MINUTES = {
  [TIME_FRAMES.ONE_MINUTE]: CHART_RANGE_MINUTES.MINUTE,
  [TIME_FRAMES.FIVE_MINUTES]: CHART_RANGE_MINUTES.FIVE_MINUTES,
  [TIME_FRAMES.FIFTEEN_MINUTES]: CHART_RANGE_MINUTES.FIFTEEN_MINUTES,
  [TIME_FRAMES.ONE_HOUR]: CHART_RANGE_MINUTES.HOUR,
  [TIME_FRAMES.FOUR_HOURS]: CHART_RANGE_MINUTES.FOUR_HOURS,
  [TIME_FRAMES.TWELVE_HOURS]: CHART_RANGE_MINUTES.TWELVE_HOURS,
  [TIME_FRAMES.DAY]: CHART_RANGE_MINUTES.DAILY,
  [TIME_FRAMES.WEEKLY]: CHART_RANGE_MINUTES.WEEKLY,
};

export const TIME_FRAME_TO_CHART_RANGE = {
  [TIME_FRAMES.ONE_MINUTE]: CHART_RANGE_MINUTES.DAILY,
  [TIME_FRAMES.FIVE_MINUTES]: CHART_RANGE_MINUTES.FIVE_DAYS,
  [TIME_FRAMES.FIFTEEN_MINUTES]: CHART_RANGE_MINUTES.FIFTEEN_DAYS,
  [TIME_FRAMES.ONE_HOUR]: CHART_RANGE_MINUTES.TWO_MONTHS,
  [TIME_FRAMES.FOUR_HOURS]: CHART_RANGE_MINUTES.EIGHT_MONTHS,
  [TIME_FRAMES.TWELVE_HOURS]: CHART_RANGE_MINUTES.TWO_YEARS,
  [TIME_FRAMES.DAY]: CHART_RANGE_MINUTES.FOUR_YEARS,
  [TIME_FRAMES.WEEKLY]: CHART_RANGE_MINUTES.SIXTEEN_YEARS,
};

// Defaults to USD
export const CURRENCY_CODES = {
  USD: 'USD',
  TOKEN: 'TOKEN',
};

export const PERCENT_CHANGE_HELPER_TEXT: Record<string, string> = {
  '1': '(24h)',
  '5': '(5d)',
  '15': '(15d)',
  '60': '(2m)',
  '240': '(8m)',
  '720': '(2y)',
  '1D': '(4y)',
  '7D': '(16y)',
};

/**
 * Line Chart Data Accessors - gets corresponding date and closing price pair.
 */
export const getDate = (d: TokenPrice) => new Date(d.date);
export const getTokenValue = (d: TokenPrice) => {
  if (d) {
    return d.closing;
  } else {
    return 0;
  }
};

/**
 * Filters data returned from useChartData to include date and closing values only.
 * The getBars endpoint may return with a status of 'ok' when the closing prices
 * are defined as null. In this case we want to return an empty array to help us
 * determine if the error chart should instead be displayed.
 */
export const filterChartData = (chartData: GetTimeSeriesResponse): TokenPrice[] | undefined => {
  const tokenPrices: TokenPrice[] = [];
  const chartDataLength = chartData?.['c'].length;
  for (let i = 0; i < chartDataLength - 2; i++) {
    const date = new Date(chartData?.['t'][i] * 1000);
    const closing = chartData?.['c'][i];
    if (closing !== null) {
      const price: TokenPrice = { closing: closing, date: date.toLocaleString('en-US') };
      tokenPrices.push(price);
    }
  }
  if (tokenPrices.length < 1) {
    return undefined;
  }
  return tokenPrices;
};

/**
 * Calculates the respective Token A / Token B prices.
 */
export const calculatePairPrices = (
  activeTokenChartData: GetTimeSeriesResponse,
  inactiveTokenChartData: GetTimeSeriesResponse,
  isInverse: boolean,
) => {
  const tokenPrices: TokenPrice[] = [];
  const chartDataLength = Math.min(activeTokenChartData.c.length, inactiveTokenChartData.c.length);
  for (let i = 0; i < chartDataLength - 2; i++) {
    const date = new Date(activeTokenChartData.t[i] * 1000);
    const activeTokenClosingPrice = activeTokenChartData.c[i];
    const inactiveTokenClosingPrice = inactiveTokenChartData.c[i];
    if (
      typeof activeTokenClosingPrice === 'number' &&
      typeof inactiveTokenClosingPrice === 'number'
    ) {
      const closing = isInverse
        ? inactiveTokenClosingPrice / activeTokenClosingPrice
        : activeTokenClosingPrice / inactiveTokenClosingPrice;
      const price: TokenPrice = { closing: closing, date: date.toLocaleString('en-US') };
      tokenPrices.push(price);
    }
  }
  if (tokenPrices.length < 1) {
    return undefined;
  }
  return tokenPrices;
};

/**
 * Calculates all price points (open, high, low, close) for
 * Token A / Token B and returns in GetTimeSeriesResponse format.
 */
export const calculatePairPricesTradingView = (
  activeTokenChartData: GetTimeSeriesResponse,
  inactiveTokenChartData: GetTimeSeriesResponse,
  isInverse: boolean,
): GetTimeSeriesResponse | undefined => {
  const chartDataLength = Math.min(activeTokenChartData.c.length, inactiveTokenChartData.c.length);

  // Initialize arrays for each price type
  const timestamps: number[] = [];
  const o: number[] = [];
  const h: number[] = [];
  const l: number[] = [];
  const c: number[] = [];
  const volume: string[] = [];

  for (let i = 0; i < chartDataLength - 2; i++) {
    const activeTokenPrices = {
      o: activeTokenChartData.o[i],
      h: activeTokenChartData.h[i],
      l: activeTokenChartData.l[i],
      c: activeTokenChartData.c[i],
    };

    const inactiveTokenPrices = {
      o: inactiveTokenChartData.o[i],
      h: inactiveTokenChartData.h[i],
      l: inactiveTokenChartData.l[i],
      c: inactiveTokenChartData.c[i],
    };

    // Check if all values are valid numbers
    if (
      Object.values(activeTokenPrices).every((price) => typeof price === 'number') &&
      Object.values(inactiveTokenPrices).every((price) => typeof price === 'number')
    ) {
      timestamps.push(activeTokenChartData.t[i]);

      o.push(
        isInverse
          ? inactiveTokenPrices.o / activeTokenPrices.o
          : activeTokenPrices.o / inactiveTokenPrices.o,
      );

      h.push(
        isInverse
          ? inactiveTokenPrices.h / activeTokenPrices.h
          : activeTokenPrices.h / inactiveTokenPrices.h,
      );

      l.push(
        isInverse
          ? inactiveTokenPrices.l / activeTokenPrices.l
          : activeTokenPrices.l / inactiveTokenPrices.l,
      );

      c.push(
        isInverse
          ? inactiveTokenPrices.c / activeTokenPrices.c
          : activeTokenPrices.c / inactiveTokenPrices.c,
      );

      volume.push(activeTokenChartData.volume[i]);
    }
  }

  if (timestamps.length < 1) {
    return undefined;
  }

  return {
    t: timestamps,
    o,
    h,
    l,
    c,
    volume,
    s: 'ok',
  };
};

/**
 * Calculates Percent Change from 0th index to last index in the TokenPrice Array.
 * The TokenPrice array passed in here can also be sliced from the tooltip handler in LineChart.tsx
 * @param chartData - TokenPrice[] after filtered to include closing and date values only.
 * @returns Percent - Change formatted string,
 */
export const getPercentageChange = (chartData: TokenPrice[]): number => {
  const firstValue = chartData[0]?.closing;
  const lastValue = chartData[chartData.length - 1]?.closing;
  if (firstValue === lastValue) {
    return 0.0;
  } else {
    const percentChange = ((lastValue - firstValue) / firstValue) * 100;
    return percentChange;
  }
};
/**
 * Returns an array of item labels for the market view drop down component on the charts.
 * Note: This function does not format the labels.
 * @param activeTokenSymbolFormatted - required
 * @param inactiveTokenSymbolFormatted - optional - If defined, returns 3 items, if not only 1
 * @returns array of `{key : number, label: string}` object for each market view option
 */
export const getMarketViewDropDownItemLabels = ({
  activeTokenSymbolFormatted,
  inactiveTokenSymbolFormatted,
}: {
  activeTokenSymbolFormatted: string;
  inactiveTokenSymbolFormatted?: string;
}) => {
  let itemLabelObjects = [
    {
      key: 0,
      label: `${activeTokenSymbolFormatted} / USD`,
    },
  ];
  if (inactiveTokenSymbolFormatted && activeTokenSymbolFormatted) {
    itemLabelObjects.push(
      {
        key: 1,
        label: `${activeTokenSymbolFormatted} / ${inactiveTokenSymbolFormatted}`,
      },
      {
        key: 2,
        label: `${inactiveTokenSymbolFormatted} / ${activeTokenSymbolFormatted}`,
      },
    );
  }
  return itemLabelObjects;
};

/**
 * Calculates the Live Price Rendered on the Charts component depending on chart view state.
 * @param activeTokenPrice - Active Token USD Price
 * @param otherTokenPrice - Other Token USD Price (optional)
 * @param isTokenPairViewActive - boolean
 * @param isInverse - boolean
 * @returns number or undefined if number is not finite.
 */
export function getLivePriceAmount(
  activeTokenPrice: number,
  otherTokenPrice?: number,
  isInverse?: boolean,
  isTokenPairViewActive?: boolean,
) {
  if (!isTokenPairViewActive || otherTokenPrice === undefined) {
    return activeTokenPrice;
  } else {
    const tokenRate = isInverse
      ? otherTokenPrice / activeTokenPrice
      : activeTokenPrice / otherTokenPrice;
    if (!Number.isFinite(tokenRate)) {
      return undefined;
    }
    return tokenRate;
  }
}

/**
 * Scales the bounds of a range to make sure it's at least a 5% spread
 * This is used to make sure the y axis always shows at least 5% worth of change
 * @param min - Minimum number
 * @param max - Max number
 */
export const scaleDomainToAtLeastMinimumThreshold = (min: number, max: number) => {
  // Find the midpoint between the max and min
  const midpoint = mean([min, max]);
  // Compute the minimum y value 2.5% away from the midpoint to show (total absolute % show is 5% (2.5% on both sides))
  var percent = [0.97, 1.03];
  const percentageAwayFromAvgMin = (midpoint ?? 0) * percent[0];
  const percentageAwayFromAvgMax = (midpoint ?? 0) * percent[1];

  // Here we ensure that our data shows a minimum y-axis of 5% change.
  // Otherwise tiny changes over stable periods show as huge voltaility to the user
  // (eg when a stablecoin changes 0.05% this makes it show stable y values)
  const dataMinToUse = Math.min(min, percentageAwayFromAvgMin);
  const dataMaxToUse = Math.max(max, percentageAwayFromAvgMax);

  return {
    scaledMin: dataMinToUse,
    scaledMax: dataMaxToUse,
  };
};

/**
 * Util function used to format axis labels according to time series range.
 * @param tokenDates - Array of dates from the data used for the line chart
 * @param range - Chart Range in Minutes
 * @returns tick labels with appropiate formatting
 */
export const scaledAxisTickLabels = ({
  tokenDates,
  range,
}: {
  tokenDates: Date[];
  range: number;
}) => {
  const binLength = Math.ceil(tokenDates?.length / 9);
  const halfBinLength = Math.ceil(binLength / 2);
  const newArray = [];
  switch (range) {
    case 0: {
      for (let i = tokenDates.length - 1; i > 0; i--) {
        newArray.push({
          date: tokenDates[i].toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' }),
        });
      }
      break;
    }
    case CHART_RANGE_MINUTES.HOUR: {
      for (let i = halfBinLength; i < tokenDates.length; i += binLength) {
        newArray.push({
          date: tokenDates[i].toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' }),
        });
      }
      break;
    }
    case CHART_RANGE_MINUTES.DAILY: {
      let day = tokenDates[halfBinLength]?.getDay();
      for (let i = halfBinLength; i < tokenDates.length; i += binLength) {
        if (tokenDates[i]?.getDay() !== day) {
          day = tokenDates[i]?.getDay();
          newArray.push({
            date: tokenDates[i + 1]?.toLocaleString('en-US', { month: 'short', day: 'numeric' }),
          });
        } else {
          newArray.push({ date: tokenDates[i]?.toLocaleString('en-US', { hour: 'numeric' }) });
        }
      }
      break;
    }
    case CHART_RANGE_MINUTES.WEEKLY: {
      let day = tokenDates[halfBinLength]?.getDay();
      for (let i = halfBinLength; i < tokenDates.length; i += binLength) {
        if (tokenDates[i]?.getDay() !== day) {
          day = tokenDates[i]?.getDay();
          newArray.push({
            date: tokenDates[i]?.toLocaleString('en-US', { month: 'short', day: 'numeric' }),
          });
        } else {
          newArray.push({ date: tokenDates[i]?.toLocaleString('en-US', { hour: 'numeric' }) });
        }
      }
      break;
    }
    case CHART_RANGE_MINUTES.MONTHLY: {
      for (let i = halfBinLength; i < tokenDates.length; i += binLength) {
        newArray.push({
          date: tokenDates[i]?.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
          }),
        });
      }
      break;
    }
    case CHART_RANGE_MINUTES.YEARLY: {
      for (let i = binLength; i < tokenDates.length; i += binLength) {
        newArray.push({
          date: tokenDates[i]?.toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            year: '2-digit',
          }),
        });
      }
      break;
    }
    case CHART_RANGE_MINUTES.MAX: {
      for (let i = binLength; i < tokenDates.length; i += binLength) {
        newArray.push({
          date: tokenDates[i]?.toLocaleString('en-US', { month: 'short', year: 'numeric' }),
        });
      }
      break;
    }
  }
  return newArray;
};
