import { useMemo } from 'react';
import useSWR from 'swr';
import { SWRConfiguration } from 'swr';

import { ChartParams } from '@/components/Charts/utils';

import { GetTimeSeriesResponse } from '../utils/defined.types';
import { routes } from '../utils/routes';
import { objectToQueryString } from '../utils/string';
import { fetcher } from '../utils/swr';

/**
 * Fetches Historical Price Data for specified token, resolution, and date range from the getBars endpoint from Defined.fi.
 *
 * Note - Should not need unique key unless another hook is introduced which consumes useSWR with exact parameters.
 */
export const useTimeSeries = ({
  address,
  chainId,
  chartRangeInMinutes,
  resolution,
  currencyCode,
  removeLeadingNullValues,
  swrOptions,
}: ChartParams) => {
  const path = useMemo(() => {
    if (
      !address ||
      !chainId ||
      !chartRangeInMinutes ||
      !resolution ||
      !currencyCode ||
      typeof removeLeadingNullValues !== 'boolean'
    ) {
      return;
    }
    const now = Date.now() - 1000 * 20; // now date minus 20 seconds. This is to ensure that the latest data is always available.
    const parameters = {
      address,
      chainId,
      chartRangeInMinutes,
      resolution,
      currencyCode,
      removeLeadingNullValues,
      now,
    };

    return `${routes.api.TIME_SERIES}${objectToQueryString(parameters)}`;
  }, [address, chainId, chartRangeInMinutes, resolution, currencyCode, removeLeadingNullValues]);

  const options: SWRConfiguration = {
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    shouldRetryOnError: false,
    refreshWhenHidden: false,
    refreshWhenOffline: false,
    ...swrOptions,
  };

  return useSWR<GetTimeSeriesResponse>(path, fetcher, options);
};
